import React from "react";
import { graphql } from "gatsby";
import GatsbyImage from "gatsby-image";
import PageWrapper from "components/PageWrapper";
import log from "utils/log";
import RichText from "components/RichText";
import * as siteTheme from "theme";
import * as heroClasses from "./hero.module.css";

export const query = graphql`
  query($id: String!) {
    happyTail: contentfulHappyTail(id: { eq: $id }) {
      id
      name
      description {
        raw
      }
      author
      teaser
      teaserImage {
        fixed {
          src
        }
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;

function present(data) {
  log("[PAGE] data", data);

  const seo = {
    title: data?.happyTail?.name,
    image: data?.happyTail?.teaserImage?.fixed?.src,
    description:
      "Nana's Haven in St. Petersburg, FL offers support and services for neglected and abused animals. Learn more about us today.",
  };

  const presentation = {
    seo,
    happyTail: data.happyTail,
  };

  log("[PAGE] presentation", presentation);

  return presentation;
}

export default function HappyTail({ data }) {
  const { seo, happyTail } = present(data);

  return (
    <PageWrapper seo={seo}>
      {/* Hero ---> */}
      <div className="container mx-auto px-8 md:px-16">
        <div className={heroClasses.heroLayout}>
          <div className={heroClasses.heroText}>
            <div className={heroClasses.heroTextLayout}>
              <h1 className={heroClasses.heroTitle}>{happyTail.name}</h1>
              <div className={heroClasses.heroContent}>
                <RichText
                  json={happyTail.description}
                  theme={{
                    title: {
                      color: siteTheme.heading.primary,
                      fontFamily: siteTheme.font.primary,
                    },
                  }}
                />
                <div>~ {happyTail.author}</div>
              </div>
            </div>
          </div>
          <div className={heroClasses.heroImage}>
            <GatsbyImage fluid={happyTail.teaserImage.fluid} />
          </div>
        </div>
      </div>
      {/* <---- Hero */}
    </PageWrapper>
  );
}
